import React from 'react';

import './tag.scss';

export const TagList = ({ type, tags }) => {
  if (!tags || tags.length < 1) {
    return (
      <div>
        <span>No categories...</span>
      </div>
    );
  }
  return (
    <div className="tag-list">
      {tags.map(tag => (
        <div key={tag} className={type === 'card' ? 'ipd-card__tag' : 'tag'}>
          {tag}
        </div>
      ))}
    </div>
  );
};
